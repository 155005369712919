<template lang="pug">
  .mark-up-fee
    AppNumberInput(
      v-if="isFixedTypeSelected"
      :class="{ invalid, updated }"
      use-delimiter
      :max="9999999"
      :value="value"
      @input="handleInput"
    )
    .app-number-input(v-else)
      BFormInput(
        type="text"
        :class="{ invalid, updated }"
        :value="value"
        :formatter="decimalFormatter"
        @input="handleInput"
      )
    AppDropdown(
      close-on-select
      :disabled="typesDisabled"
      :value="selectedMarkupType"
      :items="markupTypes"
      @select="selectMarkupType"
    )
</template>

<script>
  // components
  import AppNumberInput from "@/components/elements/AppNumberInput"

  // misc
  import { find } from "lodash-es"
  import { decimalFormatter } from "@/helpers/common"
  import { PERCENT_MARKUP, FIXED_MARKUP } from "@/config/constants"

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppInput: () => import("@/components/elements/AppInput"),
      AppNumberInput
    },

    watch: {
      markupType: {
        handler() {
          if (this.isFixedTypeSelected) {
            this.handleInput(Number.parseInt(this.value))
          }
        }
      }
    },

    data() {
      return {
        markupTypes: [PERCENT_MARKUP, FIXED_MARKUP]
      }
    },

    props: {
      value: {
        type: Number,
        default: null
      },
      updated: {
        type: Boolean,
        default: false
      },
      invalid: {
        type: Boolean,
        default: false
      },
      markupType: {
        type: String,
        default: PERCENT_MARKUP.id
      },
      typesDisabled: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      isFixedTypeSelected() {
        return this.markupType === FIXED_MARKUP.id
      },

      selectedMarkupType() {
        return find(this.markupTypes, ({ id }) => id === this.markupType)
      }
    },

    methods: {
      decimalFormatter,

      handleInput(value) {
        this.$emit("change-markup-fee", { value: Number(value) })
      },

      selectMarkupType({ id }) {
        this.$emit("change-markup-fee", { type: id })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  =default-input
    font-size: 0.8rem
    text-align: right
    height: 34px
    width: 178px

  =updated-input
    background-color: $default-purple-light
    border: 1px solid $default-purple-light-line

  .mark-up-fee
    +styled-inputs
    display: flex
    justify-content: left

    input
      &.updated
        +updated-input

      &.invalid
        +default-invalid-input

      +default-input

    .app-number-input
      margin-top: 1px

      &.updated
        ::v-deep
          input
            +updated-input

      &.invalid
        ::v-deep
          input
            +default-invalid-input

      ::v-deep
        input
          +default-input

    ::v-deep
      .app-select
        padding: 0
        margin-left: 10px
        width: 92px
        height: 18px

        .dropdown-toggle
          height: 33px
          border: 1px solid #acb2f3
</style>
